exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ansvarlig-rapportering-tsx": () => import("./../../../src/pages/ansvarlig-rapportering.tsx" /* webpackChunkName: "component---src-pages-ansvarlig-rapportering-tsx" */),
  "component---src-pages-boliglansforhandleren-tsx": () => import("./../../../src/pages/boliglansforhandleren.tsx" /* webpackChunkName: "component---src-pages-boliglansforhandleren-tsx" */),
  "component---src-pages-brukervilkar-tsx": () => import("./../../../src/pages/brukervilkar.tsx" /* webpackChunkName: "component---src-pages-brukervilkar-tsx" */),
  "component---src-pages-hvem-er-horde-plus-tsx": () => import("./../../../src/pages/hvem-er-horde-plus.tsx" /* webpackChunkName: "component---src-pages-hvem-er-horde-plus-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informasjonskapsler-tsx": () => import("./../../../src/pages/informasjonskapsler.tsx" /* webpackChunkName: "component---src-pages-informasjonskapsler-tsx" */),
  "component---src-pages-lag-egne-assistenter-tsx": () => import("./../../../src/pages/lag-egne-assistenter.tsx" /* webpackChunkName: "component---src-pages-lag-egne-assistenter-tsx" */),
  "component---src-pages-okonomiassistenten-tsx": () => import("./../../../src/pages/okonomiassistenten.tsx" /* webpackChunkName: "component---src-pages-okonomiassistenten-tsx" */),
  "component---src-pages-personvern-tsx": () => import("./../../../src/pages/personvern.tsx" /* webpackChunkName: "component---src-pages-personvern-tsx" */),
  "component---src-pages-responsible-disclosure-tsx": () => import("./../../../src/pages/responsible-disclosure.tsx" /* webpackChunkName: "component---src-pages-responsible-disclosure-tsx" */)
}

